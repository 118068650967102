/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/

// Footer Info Box 
.footer-info-box{
    padding: 275px 0 195px; 

    @media #{$desktop-device}{
        padding-top: 230px;
        padding-bottom: 150px;
    }

    @media #{$tablet-device}{
        padding-top: 200px;
        padding-bottom: 120px;
    }

    @media #{$large-mobile}{
        padding-top: 180px;
        padding-bottom: 100px;
    }

    @media #{$small-mobile}{
        padding-top: 150px;
        padding-bottom: 70px;
    }

    & .title{
        font-size: 24px;
        font-family: $custom-font-family;
        font-weight: 400;
        color: rgba($white, 0.5);

        @media #{$large-mobile}{
            font-size: 20px;
        }
        @media #{$small-mobile}{
            font-size: 16px;
        }
    }
    & .mail{
        & a{
            font-size: 60px;
            font-weight: 700;
            color: $primary;
            margin-top: 10px;
            display: inline-block;
            transition: $transition-base;

            @media #{$large-mobile}{
                font-size: 40px;
            }

            @media #{$small-mobile}{
                font-size: 20px;
            }

            &:hover{
                color: $white;
            }
        }
    }
}


// Footer Copyright & Social 
.footer-copyright-social{
    display: flex;
    justify-content: space-between;
    padding-bottom: 70px;
    padding-top: 100px;

    @media #{$large-mobile}{
        display: block;
        text-align: center;
    }
}

// Footer Copyright
.footer-copyright{
    padding-bottom: 20px;
    
    & span{
        color: $white;
    }
    & i{
        color: $red;
    }
    & a{
        color: $white;

        &:hover{
            color: $primary;
        }
    }
}

// Footer Social 
.footer-social{
    padding-bottom: 20px;

    & .social{
        display: flex;
        
        @media #{$large-mobile}{
            justify-content: center;
            flex-wrap: wrap;
        }

        & li{
            & + li{
                margin-left: 35px;
            }

            & a{
                font-size: 16px;

                @media #{$tablet-device, $small-mobile}{
                    font-size: 14px;
                }
                

                &.twitter{
                    color: #00ccff;
                }
                &.behance{
                    color: #0066ff;
                }
                &.dribbble{
                    color: #ff0066;
                }
                &.github{
                    color: #ffffff;
                }
            }
        }
    }
}


/*-------- Scroll To Top Style Css --------*/

.scroll-to-top {
    position: fixed;
    right: 30px;
    bottom: -60px;
    z-index: 999;
    box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
    display: block;
    padding: 0;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 18px;
    line-height: 50px;
    border: 0;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    background-color: $primary;
    background-size: 200% auto;
    background-position: left center;
    color: $white;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

  
    &.show {
        visibility: visible;
        opacity: 1;
        bottom: 50px;
    }
  
    i {
        position: absolute;
        top: 50%;
        left: 50%;
        color: $white;
        transition: all 0.3s ease-in-out;
    }
  
    .arrow-top {
        transform: translate(-50%, -50%);
    }
  
    .arrow-bottom {
        transform: translate(-50%, 80px);
    }
  
    &:hover {
        background-position: right center;
        .arrow-top {
            transform: translate(-50%, -80px);
        }
        .arrow-bottom {
            transform: translate(-50%, -50%);
        }
    }
  }



