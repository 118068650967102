/*--
/*  4.6 - Testimonial CSS
/*----------------------------------------*/

// Testimonial Wrapper
.testimonial-wrapper{
    border-top: 1px solid rgba($white, 0.15);
}

// Single Testimonial 
.single-testimonial {
    background-color: $dark;
    padding: 90px 35px 45px;
    position: relative;    

    & i{
        font-size: 40px;
        line-height: 1;
        color: $primary;
        position: absolute;
        top: 32px;
        right: 40px;
    }

    & .testimonial-content {
        & .name {
            & .author-name {
                font-weight: 700;
                font-size: 20px;
                color: $white;
            }
            & span {
                font-size: 20px;
                font-weight: 300;
            }
        }

        & p {
            font-size: 14px;
            font-weight: 400;
            margin-top: 20px;
            margin-bottom: 0;
        }
    }

    &::before {
        position: absolute;
        width: 0;
        height: 5px;
        background: $primary;
        content: "";
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        transition: .6s;
    }

    &:hover {
        &::before {
            width: 100%;
            transition: .6s;
        }
    }
}

// Testimonial  Carousel 
.testimonial-carousel{
    padding-top:60px;
    position: relative;
    // Responsive
    @media #{$tablet-device}{
        padding-top: 40px;
    }
    @media #{$large-mobile}{
        padding-top: 30px;
    }

    & .swiper-button-prev,
    & .swiper-button-next {
        display: flex;
        align-items: center;
        top: 0;

        &:focus{
            border: 0px;
            outline: 0px;
        }
        &:after{
            display: none;
        }
        & i{ 
            opacity: 1;
            visibility: visible;
            font-size: 30px;
            color: $body-color;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            transition: $transition-base;

            &:hover{
                color: $white;
            }
        }
    }
    & .swiper-button-prev {
        left: auto;
        right: 35px;
    }
    & .swiper-button-next {
        right: 0px;
    }
}

