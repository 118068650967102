/*--
/*  4.14 - FAQ's CSS
/*----------------------------------------*/


// FAQ's Wrapper 
.faq-wrapper{
    
}


// FAQ's Menu 
.faq-menu{
    padding-top: 53px;
    // Responsive
    @media #{$tablet-device , $large-mobile}{
        padding-top: 30px;
    }

    & .nav{
        & li{
            margin-bottom: 12px;

            & a{
                font-family: $headings-font-family;
                font-size: 20px;
                font-weight: 400;
                color: $white;
                transition: $transition-base;
                text-transform: uppercase;

                &:hover,
                &.active{
                    color: $primary;
                }
            }
        }
    }
}

// Single FAQ 
.single-faq{
    padding-top: 55px;
    // Responsive
    @media #{$tablet-device , $large-mobile}{
        padding-top: 30px;
    }

    & .title{
        font-size: 36px;
        font-weight: 700;
        color: $white;

        @media #{$desktop-device, $tablet-device}{
            font-size: 30px;
        }
        @media #{$large-mobile}{
            font-size: 24px;
        }
    }
    & p{
        margin-top: 18px;
    }
}









